export const getLabel = (
  options: { label: string; value: string }[],
  value: string
): string => {
  const option = options.find((option) => option.value === value);
  if (!option) return null;
  return option.label;
};

export const transformInToFormObject = (data): FormData => {
  const formData = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((obj, index) => {
        const keyList = Object.keys(obj);
        keyList.forEach((keyItem) => {
          const keyName = [key, "[", index, "]", ".", keyItem].join("");
          formData.append(keyName, obj[keyItem]);
        });
      });
    } else if (typeof data[key] === "object") {
      for (const innerKey in data[key]) {
        formData.append(`${key}.${innerKey}`, data[key][innerKey]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  return formData;
};

export const dataURLtoBlob = (dataurl: string): Blob => {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

export const dataURLtoBlobWithMime = (
  dataurl: string
): { file: Blob; mime: string } => {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1];

  return {
    file: dataURLtoBlob(dataurl),
    mime,
  };
};

export const dataURLtoFileList = (
  dataurl: string,
  fileName: string
): FileList => {
  const blobObject = dataURLtoBlobWithMime(dataurl);
  const fileObject = new File([blobObject.file], fileName, {
    type: blobObject.mime,
  });
  const dt = new DataTransfer();

  dt.items.add(fileObject);

  return dt.files;
};

export const prependURL = (url: string): string => {
  url = url.trim();

  if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+?:)?\/\/)/, "http://");
};

export type ImageSize = { width: number; height: number };

export const imageSize = (url: string): Promise<ImageSize> => {
  if (!url) return null;

  const img = document.createElement("img");

  const promise = new Promise((resolve: (value: ImageSize) => void, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      resolve({ width, height });
    };

    img.onerror = reject;
  });

  img.src = url;

  return promise;
};

export const FIVE_MB = 5242880;

export const formatPrice = (
  price: number | string,
  currency: string
): string => {
  const formatter = new Intl.NumberFormat("pl-PL", {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    useGrouping: true,
    style: "currency",
    currency: currency,
  });

  return formatter.format(
    typeof price === "string" ? parseFloat(price) : price
  );
};

export const YOUTUBE_URL_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;

export const mainSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  adaptiveHeight: true,
};
