import React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertProps,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

type Props = {
  message?: string;
};

const ErrorMessage = ({
  message,
  ...props
}: Props & AlertProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems={"center"}
      justifyContent="center"
      pl={"0px"}
      pr={"0px"}
      mt={2}
      mb={2}
      mr={{ base: 5, md: 20 }}
      ml={{ base: 5, md: 20 }}
    >
      <Alert
        status="error"
        p={3}
        my={5}
        justifyContent={"center"}
        alignItems={"center"}
        {...props}
      >
        <AlertIcon />
        <AlertDescription>{message || t("fetchError")}</AlertDescription>
      </Alert>
    </Flex>
  );
};

export default ErrorMessage;
