import {
  Box,
  Flex,
  Heading,
  Icon,
  LinkBox,
  Text,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import React, { useMemo } from "react";

import LikeAndShare from "@/components/Garage/LikeAndShare";
import AvailabilityBadge from "@/components/common/AvailabilityBadge";
import Image from "@/components/common/Image";
import ShareModal from "@/components/common/ShareModal";
import useCurrentLocation from "@/hooks/useCurrentLocation";
import { Product } from "@/types/product";
import { getDistanceLabel } from "@/utils/distance";
import { useSession } from "next-auth/react";
import { MapPin } from "react-feather";
import { formatPrice } from "@/utils/helpers";
import {
  checkIfCategoryIsAutomotive,
  checkIfCategoryIsRealEstate,
  checkIfCategoryIsService,
} from "@/utils/category";
import { getProductAdvertiserValue } from "@/utils/product";

type Props = {
  product: Product;
  mutate?: () => void;
};

const ProductCard = ({ product, mutate }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { location } = useCurrentLocation();
  const shareModal = useDisclosure();
  const { data: session } = useSession();
  const productIsService = useMemo(
    () => checkIfCategoryIsService(product?.categories?.[0]),
    [product]
  );
  const productIsRealEstate = useMemo(
    () => checkIfCategoryIsRealEstate(product?.categories?.[0]),
    [product]
  );
  const productIsAutomotive = useMemo(
    () => checkIfCategoryIsAutomotive(product?.categories?.[0]),
    [product]
  );
  const productAdvertiserValue = useMemo(
    () =>
      productIsRealEstate || productIsAutomotive
        ? getProductAdvertiserValue(product)
        : null,
    [product, productIsAutomotive, productIsRealEstate]
  );

  const onCloseShareModal = () => {
    shareModal.onClose();
  };

  return (
    <>
      <Box px="10px">
        <LinkBox px="0px" maxW="300px" mx="auto">
          <NextLink href={`/p/${product?.slug}`} passHref>
            <Flex
              position="relative"
              bg="white"
              borderRadius="0px"
              overflow="hidden"
              h="300px"
              flexDirection="column"
              borderWidth="0px"
              borderColor="green.500"
            >
              {product?.defaultPhoto ? (
                <Image
                  fileName={product?.defaultPhoto}
                  alt=""
                  defaultPhoto
                  borderRadius="10px"
                  position="relative"
                  overflow="hidden"
                  _before={{
                    content: '""',
                    pt: "65%",
                    d: "block",
                  }}
                  flex={1}
                />
              ) : (
                <Image
                  fileName={product?.photos[0]?.filename}
                  alt=""
                  position="relative"
                  overflow="hidden"
                  _before={{
                    content: '""',
                    pt: "65%",
                    d: "block",
                  }}
                  flex={1}
                />
              )}
              <AvailabilityBadge availabilityDate={product?.availabilityDate} />
              <Flex flexDir={"column"} height="35%" alignItems={"center"}>
                <Heading
                  size="sm"
                  fontSize={product?.name.length > 20 ? "13px" : "15px"}
                  variant="blue"
                  mb={0}
                  mt="10px"
                  px="5px"
                  isTruncated
                >
                  {product?.name}
                </Heading>
                <Text
                  fontWeight="500"
                  fontSize="lg"
                  textAlign="center"
                  lineHeight={1}
                  my="10px"
                  color="blue.500"
                >
                  {product?.free
                    ? t("products.free")
                    : formatPrice(product?.price, product?.currency)}
                </Text>

                <Flex gap={1}>
                  {!productIsService &&
                    !productIsRealEstate &&
                    !productIsAutomotive && (
                      <Badge colorScheme={product?.isNew ? "red" : "yellow"}>
                        {t(product?.isNew ? "new" : "used")}
                      </Badge>
                    )}
                  <Badge colorScheme={"green"}>
                    {productIsService
                      ? t(
                          product?.shipment
                            ? "servicesShipmentAllowed"
                            : "servicesShipmentNotAllowed"
                        )
                      : productIsRealEstate || productIsAutomotive
                      ? t(
                          `categories.parameter.options.${productAdvertiserValue}`
                        )
                      : t(
                          product?.shipment
                            ? "shipmentAllowed"
                            : "shipmentNotAllowed"
                        )}
                  </Badge>
                </Flex>
              </Flex>
              {location?.lat && (
                <Flex
                  fontWeight="bold"
                  position="absolute"
                  top="5px"
                  left="5px"
                  bg="white"
                  lineHeight={1}
                  p="5px 7.5px"
                  align="center"
                  borderRadius="5px"
                  color="green.500"
                  fontSize="11px"
                >
                  <Icon w="10px" h="10px" mr="5px" as={MapPin} />
                  {t("kilometersFromYouShort", {
                    distance: getDistanceLabel(
                      t,
                      product?.garage?.distance,
                      false
                    ),
                  })}
                </Flex>
              )}
            </Flex>
          </NextLink>
          <Flex position="absolute" right="5px" top="5px" zIndex={99}>
            <LikeAndShare
              garageId={product?.garage?.id}
              favourite={
                product?.productLikes
                  ? product?.productLikes?.find(
                      (like) => like?.id == session?.user?.id
                    )
                  : false
              }
              productId={product?.id}
              isUserGarage={false}
              isProduct={true}
              w={"25px"}
              h={"25px"}
              onShare={shareModal.onOpen}
              garageRefresher={mutate}
            />
          </Flex>
        </LinkBox>
      </Box>

      {shareModal.isOpen && (
        <ShareModal
          isOpen={shareModal.isOpen}
          onClose={onCloseShareModal}
          url={`/p/${product?.slug}`}
        />
      )}
    </>
  );
};

export default ProductCard;
