export const getCategoryParents = (categoryData) => {
  if (!Array.isArray(categoryData)) {
    const parentsArray = [];
    let currentParent = categoryData?.parent;

    while (currentParent) {
      parentsArray.push(currentParent);
      currentParent = currentParent?.parent;
    }

    return [{ name: "categories", id: "" }, ...parentsArray?.reverse()];
  } else return null;
};

export const getCategoryParentsParameters = (categoryData) => {
  if (!Array.isArray(categoryData)) {
    const parentsArray = [];
    let currentParent = categoryData?.parent;

    while (currentParent) {
      parentsArray.push(currentParent?.parameters);
      currentParent = currentParent?.parent;
    }

    return parentsArray?.flat()?.reverse();
  } else return null;
};

export const getCategoryMainRootParent = (categoryData) => {
  if (!Array.isArray(categoryData)) {
    let currentParent = categoryData;

    while (currentParent?.parent) {
      currentParent = currentParent?.parent;
    }

    return currentParent;
  } else return null;
};

export const checkIfCategoryIsService = (categoryData) => {
  const rootCategory = getCategoryMainRootParent(categoryData);

  return rootCategory?.name === "services";
};

export const checkIfCategoryIsRealEstate = (categoryData) => {
  const rootCategory = getCategoryMainRootParent(categoryData);

  return rootCategory?.name === "realEstate";
};

export const checkIfCategoryIsAutomotive = (categoryData) => {
  const rootCategory = getCategoryMainRootParent(categoryData);

  return rootCategory?.name === "automotive";
};
