import React from "react";
import checkIfFullFridge from "@/utils/fullFridge";
import { useTranslation } from "next-i18next";
import Head from "next/head";

const CustomHead = ({ home = false, title = null }) => {
  const { t } = useTranslation();
  return (
    <Head>
      <title>
        {home ? (checkIfFullFridge() ? `FullFridge.app - ${t("html_title_ff")}` : `GarazoweWyprzedaze.pl - ${t("html_title")}`) : (checkIfFullFridge() ? `${title} - FullFridge.app` : `${title} - GarazoweWyprzedaze.pl`)}
      </title>
    </Head>
  );
};

export default CustomHead;
