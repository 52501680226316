import React from "react";
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { Clock } from "react-feather";
import { useTranslation } from "next-i18next";
import { differenceInCalendarDays } from "date-fns";

const AvailabilityBadge = ({ availabilityDate, ...rest }) => {
  const { t } = useTranslation();

  if (!availabilityDate) return null;

  const daysDifference = differenceInCalendarDays(
    new Date(availabilityDate),
    new Date()
  );

  if (daysDifference <= 0) return null;

  return (
    <Tooltip placement="top" label={t("addItem.availabilityDateDescription")}>
      <Flex
        alignItems="center"
        bg="green.500"
        borderRadius="5px"
        p="5px 7.5px"
        lineHeight={1}
        position="absolute"
        zIndex={9}
        top="30px"
        left="5px"
        {...rest}
      >
        <Icon as={Clock} w="10px" h="10px" color="white" />
        <Text ml="5px" fontSize="11px" color="white">
          {t("products.availability", {
            days: daysDifference,
          })}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default AvailabilityBadge;
